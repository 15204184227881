<template>
  <WsMain :key="$route.fullPath">
    <div>{{_topic}}</div>
  </WsMain>
</template>

<script>
export default {
  computed: {
    _topic() {
      return this.$o_o.$h.menu.getTopicTitle(this.$route.params.topic);
    },
    _topicKey() {
      return this.$route.params.topic;
    },
  },
  methods: {
    $_setTopic() {
      this.$store.dispatch("app/setTopic", this._topicKey);
    },
  },
  watch: {
    _topicKey: {
      handler() {
        this.$_setTopic();
      },
    },
  },
  mounted() {
    this.$_setTopic();
  },
};
</script>

<style>
</style>